<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="white"
    flat
    height="60"
  >
    <v-btn
      class="mr-3"
      text
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon color="primary">
        mdi-menu
      </v-icon>
    </v-btn>

    <v-spacer />

    <p class="pt-4">
      {{ user.name }}
    </p>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="ml-2"
          text
          fab
          small
          v-on="on"
        >
          <v-icon>
            mdi-account
          </v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click="logout">
          <v-list-item-icon class="mr-1">
            <v-icon
              small
            >
              mdi-exit-to-app
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    name: 'CoreAppBar',

    data: () => ({

    }),

    computed: {
      ...mapState('layout', ['drawer']),
      ...mapState('user', ['user']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'layout/SET_DRAWER',
        setToken: 'user/SET_TOKEN',
      }),
      ...mapActions({
        logoutAction: 'logout',
      }),
      logout () {
        this.logoutAction()
        this.$router.push('/login')
      },
    },
  }
</script>

<style lang="sass">
  #app-bar
    border-bottom: 1px solid #d8dbe0 !important
</style>
